/* Override */
img {
    max-width: 100%;
    min-height: auto;
}

.container-fluid {
   max-width: 2048px;
}

.card {
  margin: 0;
  border: 0 none;
}

.btn {
  border: 0 none;
    opacity: 0.9;
    &:focus, &:hover, &.active {
      outline: none !important;
      box-shadow: none !important;
      opacity: 1;
    }
}
.page-container {
    .header {
      .jumbotron {
        box-shadow: none;
        margin: 0;
        padding-top: 70px;
        background: transparent;
      }

      .jumbotron-image {
        position: relative;
        padding-bottom: 0;
        .text-absolute{
          figure {
            margin: 0;
            h2 {
                color: #185050;
                position: absolute;
                right: 15px;
                top: 48%;
                font-size: 70px;
            }

            p {
                text-align: right;
                position: absolute;
                right: 15px;
                top: 65%;
                color: #fff;
                width: 35%;
            }
          }
        }
      }
    }

    .top-breadcrumb {
      .breadcrumb {
      }
    }

    .content {
      padding: 0 30px;
      margin-bottom: 15px;
    }
}

/* tools */
.height-100vh {
    height: 100vh;
}
.row {
    &\.equal-height {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
      > [class*='col-'] {
          display: flex;
          flex-direction: column;
        }
    }
}
.disabled {
  color: #959595;
  pointer-events: none;
  opacity: 0.4;
}

.animate__animated {
  &.loop {
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
  }
}

/* Comp */
.comp-copyright {
  font-size: 0.75rem;
  a {
    color: inherit;
  }
}

/* Tools */
.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}