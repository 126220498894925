.login-container {
    .bg-image {
      background: url('../assets/images/login-bg.jpg')  no-repeat 50% /cover;

    }
    .form {
      &\.logo {
        background: url('../assets/images/logo.png') no-repeat 0 / contain;
        height: 150px;
        background-position: calc(50%) calc(100% - 10px);
        background-size: 90%;
      }
    }

    input {
        border: 0;
        border-bottom: 1px;
        border-style: solid;
        border-color: #ccc;
        border-radius: 0;
        background: transparent;
        &:focus {
            box-shadow: none;
            border-color: #999;
        }
    }
    /* enable absolute positioning */
    .inner-addon {
        position: relative;
    }

    /* style icon */
    .inner-addon .icon {
        position: absolute;
        padding: 3px 5px 3px 0;
        pointer-events: none;
        font-size: 20px;
    }

    /* align icon */
    .left-addon .icon  { left:  0px;}
    .right-addon .icon { right: 0px;}

    /* add padding  */
    .left-addon input  { padding-left:  30px; }
    .right-addon input { padding-right: 30px; }
}