

/* make the customizations */
$theme-colors: (
    "primary": #50A080
);
$body-bg: #fafafa;

@import "~bootswatch/dist/flatly/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/flatly/bootswatch";
@import '~react-circular-progressbar/dist/styles.css';
@import '~animate.css/animate.min.css';

.icon {
    font-size: 32px;
    &.xs { font-size: 15px; }
    &.sm { font-size: 24px; }
    &.md { font-size: 32px; }
    &.lg { font-size: 64px; }
    &.xl { font-size: 128px; }
}
@import "../assets/font-icon/custom/css/agrowlab.css";